let ROOT_URL = "";
let BASE_URL = "";
let socketConnectionURL = "";
// console.log(process.env.REACT_APP_SERVER);
if (process.env.REACT_APP_SERVER?.trim() == "production") {
  ROOT_URL = "https://api.saledesk.io/api/v1/a";
  BASE_URL = "https://api.saledesk.io/api/v1";
  socketConnectionURL = "https://api.saledesk.io";
} else {
  ROOT_URL = "https://api.saledesk.io/api/v1/a";
  BASE_URL = "https://api.saledesk.io/api/v1";
  socketConnectionURL = "https://api.saledesk.io";
}
export {BASE_URL, socketConnectionURL}
export default ROOT_URL;