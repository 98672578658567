import React, { useContext,useEffect } from 'react';
import {Link, NavLink, useNavigate} from 'react-router-dom';

import DropDownMenu, { Dropdown, Menu } from "../components/common/sidebarMenu";
import { UserContext } from '../context/theme';



function Header () {
    const {reset, counts} = useContext(UserContext)
    return (
        <>
           <div className="left-sidebar-wrapper">
            <nav className="sidebar">
                <div className="sidebar-header text-center">
                    <NavLink activeclassname="isActive" to="/"><img src='/assets/images/logo.png'/></NavLink>
                    <strong><NavLink activeclassname="isActive" to='/'>FD</NavLink></strong>
                </div>
                <div className="left-nav-menu">
                <DropDownMenu>
                    <Menu path="/" title="Dashboard" icon="ic:baseline-dashboard" iconifyIcon="true"></Menu>
                    <Dropdown  title={`Vendor`}  icon="mdi:user-group" iconifyIcon="true">
                        <Menu path="/vendor/approval" title={`Pending (${counts?.unverifiedVendorsCount || 0})`} icon="mdi:account-pending" iconifyIcon="true"></Menu>
                        <Menu path="/vendor" title={`Approved (${counts?.verifiedVendorsCount || 0})`} icon="mdi:approve" iconifyIcon="true"></Menu>
                    </Dropdown>
                    <Menu path="/customers" title={`Customer (${counts?.customersCount || 0})`} icon="mdi:user" iconifyIcon="true"></Menu>
                    <Dropdown  title="General"  icon="material-symbols:connected-tv-outline-rounded" iconifyIcon="true">
                        <Menu path="/gst/1" title="GST" icon="tabler:receipt-tax" iconifyIcon="true"></Menu>
                        <Menu path="/designation/1" title="Designation" icon="carbon:skill-level-advanced" iconifyIcon="true"></Menu>
                        <Menu path="/natureOfBusiness/1" title="Nature Of Business" icon="ph:briefcase-fill" iconifyIcon="true"></Menu>
                        <Menu path="/primaryCategory/1" title="Primary Category" icon="carbon:category" iconifyIcon="true"></Menu>
                        <Menu path="/productLabel/1" title="Product Label" icon="fluent-emoji-high-contrast:label" iconifyIcon="true"></Menu>
                    </Dropdown>
                    <Dropdown  title="Information"  icon="material-symbols:menu-book-sharp" iconifyIcon="true">
                        <Dropdown  title="Faqs"  icon="wpf:faq" iconifyIcon="true">
                            <Menu path="/faqs/customer/1" title="For Customer"/>
                            <Menu path="/faqs/vendor/1" title="For Vendor"/>
                        </Dropdown>
                        <Dropdown  title="Tutorials"  icon="material-symbols:connected-tv-outline-rounded" iconifyIcon="true">
                            <Menu path="/tutorials/customer/1" title="For Customer"/>
                            <Menu path="/tutorials/vendor/1" title="For Vendor"/>
                        </Dropdown>
                        <Menu path="/contact/1" title="Contact Us" icon="material-symbols:contact-phone-outline" iconifyIcon="true"></Menu>
                        <Menu path="/information/terms" title="Terms & Conditions" icon="mdi:information-outline" iconifyIcon="true" />
                        <Menu path="/information/privacy" title="Privacy Policy" icon="mdi:information-outline" iconifyIcon="true"/>
                        <Menu path="/information/about" title="About Us" icon="mdi:information-outline" iconifyIcon="true"/>
                        <Menu path="/information/vendor/signup" title="Vendor Signup page" icon="mdi:information-outline" iconifyIcon="true"/>
                        <Menu path="/information/customer/home" title="Customer Home Page" icon="mdi:information-outline" iconifyIcon="true" />
                    </Dropdown>

                    <Menu path="/helpdesk/list/1" title="Helpdesk" icon="mdi:comment-help" iconifyIcon="true" />
                    <Dropdown  title="Subscription"  icon="material-symbols:connected-tv-outline-rounded" iconifyIcon="true">
                        <Menu path="/subscription" title="Create Subscrition"/>
                        <Menu path="/subscription/orders/1" title="Orders"/>
                    </Dropdown>
                    <Menu path="/settings" title="Settings" icon="ant-design:setting-outlined" iconifyIcon="true" />
                    


                    {/* <Menu path="/order/pending" title="Orders" icon="fa6-solid:bowl-food" iconifyIcon="true" />
                    <Menu path="/inventry/category" title="Inventry" icon="ph:notepad-fill" iconifyIcon="true" />
                    <Menu path="/table" title="Table Setup" icon="ic:baseline-chair" iconifyIcon="true"/>
                    <Menu path="/pending/product" title="Pending Request" icon="fluent:notebook-error-20-filled" iconifyIcon="true" />
                    <Menu path="/transaction" title="Transactions Reports" icon="icon-park-solid:transaction-order"  iconifyIcon="true"/>
                    <Dropdown  title="Account"  icon="mdi:account" iconifyIcon="true">
                        <Menu path="/profile" title="Profile"/>
                        <Menu path="/bank" title="Bank Details"  />
                        <Menu path="/password" title="Change Password"  />
                    </Dropdown>
                    <Dropdown  title="Setting"  icon="ant-design:setting-filled" iconifyIcon="true">
                        <Menu path="/service-product" title="Service Product"  />
                    </Dropdown> */}
                  
                </DropDownMenu>
              
                
             <ul>
             <li>
                <a title="Logout" onClick={()=>reset()}>
                 <span className="icon-log-out"></span> 
                <span className="menu_txt">Logout</span>
                    </a>
                 </li>
             </ul>
                </div>
                {/* <div className="version">
                    <span className="vs-mobile">LT-M-22</span>
                    <span className="vs-web">V LT-MAR-22</span>
                </div> */}
            </nav>
        </div> 
        </>
    );
}
export default Header;