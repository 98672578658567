

import React, {Suspense, useContext, useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import NotFound from '../components/notFound/notFound';
import { ToastContainer } from 'react-toastify';
 import Loader, { PageLoader } from '../components/loader/loader';
import TopNavbar from './topNavbar';
import { UserContext } from '../context/theme';
import Header from './header';
import Footer from './footer';
// import RegexInput from '../components/form/formInputs/regexInput';
import 'react-toastify/dist/ReactToastify.css';
import { socketConnectionURL } from '../services/api-url';
import io from "socket.io-client";
import { useEffect } from 'react';

// import CategoryEdit from '../pages/inventry/category/category-edit';


const  Dashboard = React.lazy(()=>import('../pages/dashboard'));
const  Notifications = React.lazy(()=>import('../pages/notifications'));
const  Designation = React.lazy(()=>import('../pages/designation/list'));
const  DesignationAdd = React.lazy(()=>import('../pages/designation/add'));
const  DesignationEdit = React.lazy(()=>import('../pages/designation/edit'));

const  GSTList = React.lazy(()=>import('../pages/gst/list'));
const  GSTAdd = React.lazy(()=>import('../pages/gst/add'));
const  GSTEdit = React.lazy(()=>import('../pages/gst/edit'));

const  NatureOfBusiness = React.lazy(()=>import('../pages/natureOfBusiness/list'));
const  NatureOfBusinessAdd = React.lazy(()=>import('../pages/natureOfBusiness/add'));
const  NatureOfBusinessEdit = React.lazy(()=>import('../pages/natureOfBusiness/edit'));

const  PrimaryCategory = React.lazy(()=>import('../pages/primaryCategory/list'));
const  PrimaryCategoryAdd = React.lazy(()=>import('../pages/primaryCategory/add'));
const  PrimaryCategoryEdit = React.lazy(()=>import('../pages/primaryCategory/edit'));

const  ProductLabel = React.lazy(()=>import('../pages/productLabel/list'));
const  ProductLabelAdd = React.lazy(()=>import('../pages/productLabel/add'));
const  ProductLabelEdit = React.lazy(()=>import('../pages/productLabel/edit'));

const  Tutorials = React.lazy(()=>import('../pages/tutorial/list'));
const  TutorialsAdd = React.lazy(()=>import('../pages/tutorial/add'));
const  TutorialsEdit = React.lazy(()=>import('../pages/tutorial/edit'));

const  Faqs = React.lazy(()=>import('../pages/faqs/list'));
const  FaqsAdd = React.lazy(()=>import('../pages/faqs/add'));
const  FaqsEdit = React.lazy(()=>import('../pages/faqs/edit'));

const  Vendor = React.lazy(()=>import('../pages/vendor/list'));
const  Customer = React.lazy(()=>import('../pages/customer/list'));

const  Settings = React.lazy(()=>import('../pages/global/settings'));
const  Contact = React.lazy(()=>import('../pages/contact/list'));
const  Terms = React.lazy(()=>import('../pages/information/terms'));
const  Privacy = React.lazy(()=>import('../pages/information/privacy'));
const  About = React.lazy(()=>import('../pages/information/about'));
const  VendorSignUp = React.lazy(()=>import('../pages/information/vendor/signup'));
const  CustomerHome = React.lazy(()=>import('../pages/information/customer/home'));
const CustomerProfile = React.lazy(()=>import('../pages/customer/customerview'));
const VendorProfile = React.lazy(()=>import('../pages/vendor/vendorView'));
const VendorProduct = React.lazy(()=>import('../pages/product/list'));
const VendorProductView = React.lazy(()=>import('../pages/product/view'));
const ProductBulkUpload = React.lazy(()=>import('../pages/product/bulkUpload'));
const VendorCatalogue = React.lazy(()=>import('../pages/product/catalogueList'));
const VendorCatalogueView = React.lazy(()=>import('../pages/product/catalogueView'));

const VendorCategory = React.lazy(()=>import('../pages/category/list'));
const VendorReivew = React.lazy(()=>import('../pages/review/vendorReview'));
const ProductReivew = React.lazy(()=>import('../pages/review/productReview'));

const Helpdesk = React.lazy(()=>import('../pages/helpdesk/list'));
const HelpdeskView = React.lazy(()=>import('../pages/helpdesk/view'));

const Subscription = React.lazy(()=>import('../pages/subscription/createSubscription'));
const SubscriptionOrders = React.lazy(()=>import('../pages/subscription/orderList'));

const ReviewRating = React.lazy(()=>import('../pages/reviewRating'));
// const  Profile = React.lazy(()=>import('../pages/account/profile'));
// const  BankDetails = React.lazy(()=>import('../pages/account/bankDetails/update'));
// const  ChangePassword = React.lazy(()=>import('../pages/account/changePass'));
// const  Order = React.lazy(()=>import('../pages/order/order'));
// const  OrderView = React.lazy(()=>import('../pages/order/view'));
// // const  OrderList = React.lazy(()=>import('../pages/order/orderList'));
// const  CityOprations = React.lazy(()=>import('../pages/city/city'));
// const  CityEdit = React.lazy(()=>import('../pages/city/edit'));
// const  CityAdd = React.lazy(()=>import('../pages/city/add'));
// const  Inventry = React.lazy(()=>import('../pages/inventry/inventry'));




// const  OngoingOrder = React.lazy(()=>import('../pages/order/ongoing-order'));
// const  CompletOrder = React.lazy(()=>import('../pages/order/completed-ordr'));
// const  AddMore = React.lazy(()=>import('../pages/order/add-more'));
// const  Table = React.lazy(()=>import('../pages/tableSetup/table-setup'));
// const  TableEdit = React.lazy(()=>import('../pages/tableSetup/table-edit'));
// const  Menu = React.lazy(()=>import('../pages/menu'));
// const  Product = React.lazy(()=>import('../pages/inventry/product/product-master'));
// const  PendingRequest = React.lazy(()=>import('../pages/pending-request/pending-req'));
// const  ProductAdd = React.lazy(()=>import('../pages/inventry/product/add'));
// const  Transaction = React.lazy(()=>import('../pages/transaction/transactions-report'));
// const  TransactionView = React.lazy(()=>import('../pages/transaction/transaction-view'));
// const  AddOns = React.lazy(()=>import('../pages/inventry/add-ons'));
// const  Category = React.lazy(()=>import('../pages/inventry/category/category'));
// const  SubCategory = React.lazy(()=>import('../pages/subCategory/subCategory'));
// const  EditSubCategory = React.lazy(()=>import('../pages/subCategory/edit-subCategory'));
const  Notification = React.lazy(()=>import('../pages/notifications'));
// const  ViewRequest = React.lazy(()=>import('../pages/viewRequest'));
// const  ProductService = React.lazy(()=>import('../pages/service-product'));







function Layout() {
  const context = useContext(UserContext)  
  const {setSocket, auth} = context
  const authLocal = localStorage.getItem("token");
  useEffect(() => {
      if(auth && authLocal != undefined){
          const connection = io(`${socketConnectionURL}?type=admin&token=${authLocal}`, { transports: ["websocket", "polling"] });
          // console.log(connection)
          setSocket(connection || {})
          
          connection.on("connect", () => {
              // setIsConnected(true);
              // socket.emit("joinRoom", { ticketNumber: "" });
              // console.log(socket.id);
          });

          connection.on("disconnect", () => {
              // setIsConnected(false);
          });
          return () => {
              connection.off("connect");
              connection.off("disconnect");
          };
      }

      
  }, [auth, authLocal]);

  
  return (
     
    <div className={`page-wrapper 
      ${context.Theme} 
      ${context.ThemeColor} 
      ${context.MiniSidebar?'mini-sidebar':''}      
    `}>
      <ToastContainer/>
      <Header/>
      <div className="all-content-wrapper">
      <TopNavbar/>
        <Loader/>
        
        <div className="mainContent px-3 mt-4">
          <div className="container-fluid">
            <Suspense fallback={<PageLoader/>}>
              <Routes>
                <Route path="/" element={<Dashboard/>}/>
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="/notifications" element={<Notifications/>}/>
                <Route path="/designation/:page" element={<Designation/>}/>
                <Route path="/designation/add" element={<DesignationAdd/>}/>
                <Route path="/designation/edit/:id" element={<DesignationEdit/>}/>

                <Route path="/gst/:page" element={<GSTList/>}/>
                <Route path="/gst/add" element={<GSTAdd/>}/>
                <Route path="/gst/edit/:id" element={<GSTEdit/>}/>

                <Route path="/primaryCategory/:page" element={<PrimaryCategory/>}/>
                <Route path="/primaryCategory/add" element={<PrimaryCategoryAdd/>}/>
                <Route path="/primaryCategory/edit/:id" element={<PrimaryCategoryEdit/>}/>

                <Route path="/natureOfBusiness/:page" element={<NatureOfBusiness/>}/>
                <Route path="/natureOfBusiness/add" element={<NatureOfBusinessAdd/>}/>
                <Route path="/natureOfBusiness/edit/:id" element={<NatureOfBusinessEdit/>}/>

                <Route path="/productLabel/:page" element={<ProductLabel/>}/>
                <Route path="/productLabel/add" element={<ProductLabelAdd/>}/>
                <Route path="/productLabel/edit/:id" element={<ProductLabelEdit/>}/>

                <Route path="/tutorials/:type/:page" element={<Tutorials/>}/>
                <Route path="/tutorials/:type/add" element={<TutorialsAdd/>}/>
                <Route path="/tutorials/:type/edit/:id" element={<TutorialsEdit/>}/>

                <Route path="/faqs/:type/:page" element={<Faqs/>}/>
                <Route path="/faqs/:type/add" element={<FaqsAdd/>}/>
                <Route path="/faqs/:type/edit/:id" element={<FaqsEdit/>}/>

                <Route path="/vendor/:type" element={<Vendor/>}/>
                <Route path="/vendor" element={<Vendor/>}/>
                <Route path="/vendor/view/:page" element={<VendorProfile/>}/>

                <Route path="/vendor/product/list/:vendorId" element={<VendorProduct/>}/>
                <Route path="/vendor/product/view/:vendorId/:productId" element={<VendorProductView/>}/>
                <Route path="/vendor/product/view/:vendorId/" element={<VendorProductView/>}/>
                <Route path="/vendor/product/bulkupload/:vendorId/" element={<ProductBulkUpload/>}/>

                <Route path="/vendor/category/list/:vendorId" element={<VendorCategory/>}/>
                {/* <Route path="/vendor/category/view/:vendorId/:categorytId" element={<VendorCategoryView/>}/> */}
                {/* <Route path="/vendor/category/view/:vendorId/" element={<VendorCategoryView/>}/> */}

                <Route path="/vendor/catalogue/list/:vendorId" element={<VendorCatalogue/>}/>

                <Route path="/vendor/reivew/list/:vendorId" element={<VendorReivew/>}/>
                <Route path="/product/reivew/list/:vendorId" element={<ProductReivew/>}/>

                <Route path="/helpdesk/list/:page" element={<Helpdesk/>}/>
                <Route path="/helpdesk/view/:ticketId" element={<HelpdeskView/>}/>

                <Route path="/vendor/catalogue/view/:vendorId/:productId" element={<VendorCatalogueView/>}/>
                <Route path="/vendor/catalogue/view/:vendorId/" element={<VendorCatalogueView/>}/>

                <Route path="/subscription" element={<Subscription/>}/>
                <Route path="/subscription/orders/:page" element={<SubscriptionOrders/>}/>

                <Route path="/customers" element={<Customer/>}/>

                <Route path="/settings" element={<Settings/>}/>
                <Route path="/contact/:page" element={<Contact/>}/>
                <Route path="/information/terms" element={<Terms/>}/>
                <Route path="/information/privacy" element={<Privacy/>}/>
                <Route path="/information/about" element={<About/>}/>
                <Route path="/information/customer/home" element={<CustomerHome/>}/>
                <Route path="/information/vendor/signup" element={<VendorSignUp/>}/>
                <Route path="/customers/view/:page" element={<CustomerProfile/>}/>
                {/* <Route path="/customers/customer-view" element={<CustomerProfile/>}/> */}
                <Route path="/rating/:id" element={<ReviewRating/>}/>
                <Route path="/notifications" element={<Notification/>}/>

                {/* <Route path="/profile" element={<Profile/>}/> */}
                {/* <Route path="/bank" element={<BankDetails/>}/>
                <Route path="/password" element={<ChangePassword/>}/>
                <Route path="/order/:pageNam" element={<Order/>}/>
                <Route path="/order-view" element={<OrderView/>}/>
                <Route path="/add-more" element={<AddMore/>}/>
                <Route path="/menu" element={<Menu/>}/>
                <Route path="/table" element={<Table/>}/>
                <Route path="/table-edit/:id" element={<TableEdit/>}/>
                <Route path="/pending/:pendList" element={<PendingRequest/>}/>
                <Route path="/product-add" element={<ProductAdd/>}/>
                <Route path="/transaction" element={<Transaction/>}/>
                <Route path="/transaction-view" element={<TransactionView/>}/>
                <Route path="/category-edit/:id" element={<CategoryEdit/>}/>
                <Route path="/inventry/:pageName" element={<Inventry/>}/>


                <Route path="/subcategory" element={<SubCategory/>}/>
                <Route path="/edit-subcategory/:id" element={<EditSubCategory/>}/>

                
                <Route path="/view-request" element={<ViewRequest/>}/>
                <Route path="/city-opration" element={<CityOprations/>}/>
                <Route path="/city-edit" element={<CityEdit/>}/>
                <Route path="/city-add" element={<CityAdd/>}/>
                <Route path="/service-product" element={<ProductService/>}/> */}

                {/* <Route path="/regex" element={<RegexInput/>}/> */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Layout;
